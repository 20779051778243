<template>
	<q-card :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Import on call user file
		</h1>
		<q-card-section v-if="!preview_data.length">
			<div class="text-h6 text-left">
				Import XLSX file?
				<q-toggle v-model="import_xlsx"/>
			</div>
			<q-file
				v-model="import_file"
				color="primary"
				:label="import_xlsx ? 'Select a .xlsx file to import' : 'Select a .csv comma delimited file to import'"
				class="q-my-sm"
				:accept="import_xlsx ? '.xlsx' : '.csv'"
				outlined
				clearable
			>
				<template v-slot:prepend>
					<q-icon name="file_upload" @click.stop />
				</template>
			</q-file>
			<div v-if="import_file" class="q-mt-lg">
				<q-select
					v-model="selected_country"
					:options="countries"
					class="col-md-6"
					label="Country"
					name="country"
					hint="Pick a country"
					@popup-hide="getCountries"
				/>
				<q-select
					v-model="selected_timezone"
					:options="country_timezones"
					class="col-md-6"
					label="Timezone"
					name="timezone"
					hint="Pick a timezone from the selected country"
					@popup-hide="setTimezone"
				/>
			</div>
		</q-card-section>
		<q-card-section v-if="preview_data.length">
			<q-table
				:data="preview_data"
				:columns="columns"
				style="width: 100%"
			>
				<template v-slot:body-cell-from='props'>
					<q-td :props="props">
						<div v-if="!props.value" class="flex items-center">
							<q-icon name='priority_high' color="negative"></q-icon>
							Invalid value
						</div>
						<div v-else>
							{{props.value}}
						</div>
					</q-td>
				</template>
				<template v-slot:body-cell-to='props'>
					<q-td :props="props">
						<div v-if="!props.value" class="flex items-center">
							<q-icon name='priority_high' color="negative"></q-icon>
							Invalid value
						</div>
						<div v-else>
							{{props.value}}
						</div>
					</q-td>
				</template>
				<template v-slot:body-cell-email='props'>
					<q-td :props="props">
						<div v-if="!props.value" class="flex items-center">
							<q-icon name='priority_high' color="negative"></q-icon>
							Invalid value
						</div>
						<div v-else>
							{{props.value}}
						</div>
					</q-td>
				</template>
			</q-table>
		</q-card-section>

		<q-card-actions class="flex justify-end">
			<q-btn
				v-if="import_file && !preview_data.length"
				outline
				color="primary"
				:icon-right="import_xlsx ? 'o_settings' : 'o_upload_file'"
				class="q-mr-md"
				:label="import_xlsx ? 'Analyze file' : 'Import'"
				@click="importOnCall"
			/>
			<q-btn
				v-if="preview_data.length"
				outline
				color="primary"
				icon-right="o_upload_file"
				class="q-mr-md"
				label="Import"
				@click="importOnCallFormattedFile"
			/>
			<q-btn
				v-if="preview_data.length"
				outline
				color="primary"
				icon-right="o_file_download"
				class="q-mr-md"
				label="Preview in csv"
				@click="exportOnCall"
			/>
			<q-btn
				v-if="preview_data.length"
				outline
				color="primary"
				icon-right="o_cancel"
				class="q-mr-md"
				label="Cancel"
				@click="resetValues"
			/>
		</q-card-actions>
	</q-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TimeZones from '@/utils/countries_timezones.js'
import UserAPI from '@/services/api/Users.js'
export default {
	name: 'ImporOnCalltUsers',
	data() {
		return{
			import_file: null,
			form_data: {},
			country_timezones: [],
			countries: [],
			time_zones: [],
			selected_country: null,
			selected_timezone: null,
			data_with_headers: [],
			preview_data: [],
			import_xlsx: false,
			columns: [
				{
					name: 'from',
					required: true,
					label: 'From',
					align: 'left',
					field: row => row[0],
					sortable: true,
				},
				{
					name: 'to',
					required: true,
					label: 'To',
					align: 'left',
					field: row => row[1],
					sortable: true,
				},
				{
					name: 'name',
					required: true,
					label: 'Name',
					align: 'left',
					field: row => row[2],
					sortable: true,
				},
				{
					name: 'email',
					required: true,
					label: 'Email',
					align: 'left',
					field: row => row[3],
					sortable: true,
				},
			]
		}
	},
	computed: {
		...mapGetters([ 'logged_user' ])
	},
	methods: {
		...mapActions(['updateDefaultTimezone', 'set_active_item']),
		createCountriesList() {
			this.time_zones.forEach((time_zone) => {
				this.countries.push(time_zone.name)
				if (this.$store.getters.default_timezone) {
					time_zone.timezones.filter((item) => {
						if (this.$store.getters.default_timezone === item) {
							this.selected_timezone = item
							this.selected_country = time_zone.name
							this.country_timezones = time_zone.timezones
						}
					})
				} else {
					if (time_zone.name === 'United Kingdom') {
						this.selected_timezone = time_zone.timezones[0]
						this.selected_country = time_zone.name
						this.country_timezones = time_zone.timezones
					}
				}
			})
		},
		getCountries() {
			this.selected_timezone = ''
			this.time_zones.forEach((time_zone) => {
				if (time_zone.name == this.selected_country) {
					this.country_timezones = time_zone.timezones
				}
				if (this.country_timezones.length == 1) {
					this.selected_timezone = this.country_timezones[0]
				} else {
					this.selected_timezone = ''
				}
			})
			this.setTimezone()
		},
		setTimezone() {
			this.updateDefaultTimezone(this.selected_timezone)
		},
		async importOnCall() {
			var form_data = new FormData()
			form_data.append("file", this.import_file);
			form_data.append('preferred_timezone', this.selected_timezone);
			form_data.append('is_import_file', true)

			let response = null

			if (this.import_xlsx){
				response = await UserAPI.analyzeOnCallXlsxFile(form_data)
			}else{
				response = await UserAPI.analyzeOnCallFile(form_data)
			}

			if (response.error_message){
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			if (this.import_xlsx){
				this.preview_data = response
				this.data_with_headers = Object.assign([], response);
				this.data_with_headers.unshift([ "Start", "End", "Name", "Work Email" ])
			}else{
				this.$q.notify(response)
			}
		},
		async exportOnCall() {
			let data = {'list': this.data_with_headers}
			let response = await UserAPI.exportCsv(data)
			if (response.error_message){
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			let message = 'Your on call export was scheduled with ID: ' + response['file_id'] +
				'. You will receive a message with the file on ' + this.logged_user.email + '.'
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		async importOnCallFormattedFile(){
			var form_data = new FormData()
			form_data = {
				"file_name": this.import_file.name,
				"preferred_timezone": this.preferred_timezone,
				"is_import_file": false,
				"users_list": this.data_with_headers
			}
			let response = await UserAPI.analyzeOnCallFile(form_data)
			if (response.error_message){
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
		},
		resetValues() {
			this.preview_data = []
			this.data_with_headers = []
			this.form_data = {}
		}

	},
	created() {
		this.set_active_item(5);
		this.time_zones = TimeZones.getTimeZones()
		this.createCountriesList()
	},
}
</script>
